<template>
  <div>
    <v-slide-group cycle interval="5000" class="carousel--wrapper project--slick">
      <v-slide-item v-for="(content, index) in contents" :key="index" class="carousel--slide">
        <card-unit-type :unitType="content" :allowMultiImage="false" />
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
const CardUnitType = () => import('@/components/utils/card-unit-type');

export default {
  components: {
    CardUnitType,
  },
  props: {
    contents: {
      default: [],
      require: true,
    },
  },
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
